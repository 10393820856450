<template>
  <CRow v-if="dataAvailable" class="h-100 m-0">
    <CCol cols="12" lg="12" class="pt-0">
      <div class="h-100 highlight_stage d-flex">        
        <div class="h-100 pr-1 position second">
          <div class="h-100 d-flex flex-column justify-content-end">
            <div v-if="highlightData[1]" class="text-center mb-1 item">              
              <span class="mr-1">{{highlightData[1].product_name}}</span>
              <span v-html="highlightData[1].variation_name"></span>
            </div>
            <div class="p-3 stage">
              <div class="d-flex align-items-center justify-content-center award">
                <i class="fas fa-award"></i>
              </div>
              <div v-if="highlightData[1]" class="d-flex align-items-center justify-content-center item_score">
                <span>{{highlightData[1][scoreValue]}}</span>
              </div>
            </div>
          </div>
        </div>        
        <div class="h-100 pl-2 pr-2 position first">
          <div class="h-100 d-flex flex-column justify-content-end">
            <div v-if="highlightData[0]" class="text-center mb-1 item">
              <span class="mr-1">{{highlightData[0].product_name}}</span>
              <span v-html="highlightData[0].variation_name"></span>
            </div>
            <div class="p-3 stage">
              <div class="d-flex align-items-center justify-content-center award">
                <i class="fas fa-award"></i>
              </div>
              <div v-if="highlightData[0]" class="d-flex align-items-center justify-content-center item_score">                
                <span>{{highlightData[0][scoreValue]}}</span>
              </div>          
            </div>
          </div>
        </div>
        <div class="h-100 pl-1 position third">
          <div class="h-100 d-flex flex-column justify-content-end">
            <div v-if="highlightData[2]" class="text-center mb-1 item">
              <span class="mr-1">{{highlightData[2].product_name}}</span>
              <span v-html="highlightData[2].variation_name"></span>
            </div>
            <div class="p-3 stage">
              <div class="d-flex align-items-center justify-content-center award">
                <i class="fas fa-award"></i>
              </div>
              <div v-if="highlightData[2]" class="d-flex align-items-center justify-content-center item_score">          
                <span>{{highlightData[2][scoreValue]}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>      
    </CCol>
  </CRow>
  <CRow v-else class="h-100">
    <CCol cols="12" xl="12" sm="12" class="pt-0 pb-0">
      <div class="h-100 w-100 d-flex align-items-center justify-content-center">
        <span class="text-center">{{ $t('insights.not_enough_highlight_data') }}</span>
      </div>
    </CCol>
  </CRow>  
</template>

<script>
export default {
  name: 'ProductHighlightStage',
  props: ['stageData', 'scoreValue'],
  watch: {
    $props: {
      handler() {
        if(this.stageData) {
          this.highlightData = this.getHighlights(this.stageData, 3);
          // Update the dataAvailable value if there is data available
          if(this.highlightData.length > 0) this.dataAvailable = true;
        }
      },
      deep: true,
      immediate: true,
    }
  },
  data() {
    return {
      dataAvailable: false,
      highlightData: [],
    }
  },
  methods: {
    getHighlights(array, amount) {
      var sortOnValue = this.scoreValue;
      // Clone the array to prevent infinite loops
      var highlightData = Object.values(array).slice(0);
      // Filter the highlights to make sure scores are available
      highlightData = highlightData.filter(i => ![null].includes(i[this.scoreValue]));
      // First sort the highlights on the average score
      highlightData.sort(function(x, y) {
        if(x[sortOnValue] == y[sortOnValue]) return 0;
        else if(parseInt(x[sortOnValue]) < parseInt(y[sortOnValue])) return 1;
        else return -1;
      });
      return highlightData.slice(0, amount);
    }
  }
}
</script>